import React from "react"
import { MusicIcon } from "../../images/icons"


export const MusicAsset = ({ data, className }) => {
  if (!data) { return null }
  let musicTypes = data.map((m) => m.musicType)

  // Not display when we have no music types to display
  if (musicTypes.length === 0) {
    return null
  }

  let lastItem = null;
  if (musicTypes.length > 1) {
    lastItem = musicTypes[musicTypes.length - 1]
    delete musicTypes[musicTypes.length - 1]
  }

  // Always add an ampersand at the end of the string
  const musicstring = musicTypes.length > 1 ? [musicTypes.filter(m => m).join(', '), lastItem].join(' & ') : musicTypes[0]

  return (<span className={className}>
    <MusicIcon />
    {musicstring}
  </span>)
}
export default MusicAsset
