import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App.js';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'


// Enable GTM only on live site
if (process.env.ENVIRONMENT !== 'production') {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CODE })
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_CMS_URL,
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

// const client = ...

// client
//   .query({ query: PageTransportationQuery, })
//   .then((result) => console.log(result));

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./service-worker.js');
}
