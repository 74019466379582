import { useQuery } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import { Outlet } from "react-router-dom"
import { AppContext } from '../../common/appContext.jsx'
import Loading from '../../components/loading/index.jsx'
import GET_SITE_INFO from '../../queries/site.js'
import PublicFooter from './PublicFooter.jsx'
import PublicHeader from './PublicHeader.jsx'
import Topbar from './Topbar.jsx'


export const PublicLayout = () => {
    const { language } = useContext(AppContext)
    const { loading, error, data } = useQuery(GET_SITE_INFO(language || 'en'))
    const { setLanguageList } = useContext(AppContext)
    const { setContactInfo } = useContext(AppContext)

    useEffect(() => {
        if (data?._site?.locales && Array.isArray(data?._site?.locales)) {
            setLanguageList(data._site?.locales)
            setContactInfo(data?.contactinfo)
        }
    }, [data, setLanguageList, setContactInfo])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return (
        <>
            <Topbar />
            <PublicHeader />
            <main>
                <Outlet />
            </main>
            <PublicFooter site={data._site} />
        </>
    )
}

export default PublicLayout
