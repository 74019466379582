import React from 'react';

export const InstagramIcon = ({ className }) => (
    <svg
        viewBox="0 0 730 730"
        xmlSpace="preserve"
        width={682.667}
        height={682.667}
        xmlns="http://www.w3.org/2000/svg"
        className={`${className || ''} fill-current inline text-base`}
    >
        <defs>
            <clipPath clipPathUnits="userSpaceOnUse" id="a">
                <path d="M0 512h512V0H0Z" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
            <path
                d="M0 0c-.05-21.046-.288-42.093-.576-63.138-.348-25.391-.74-50.793-7.108-75.749-6.677-26.17-19.847-48.74-40.337-65.449-21.592-17.608-47.69-26.863-75.858-28.576-37.36-2.272-74.736-3.17-112.111-3.082-37.376-.088-74.752.81-112.111 3.082-28.168 1.713-54.266 10.968-75.858 28.576-20.49 16.709-33.66 39.279-40.338 65.449-6.368 24.956-6.76 50.358-7.107 75.749-.288 21.045-.527 42.092-.576 63.138.049 21.046.288 42.093.576 63.138.347 25.391.739 50.793 7.107 75.749 6.678 26.17 19.848 48.74 40.338 65.449 21.592 17.608 47.69 26.863 75.858 28.576 37.359 2.272 74.735 3.17 112.111 3.082 37.375.088 74.751-.81 112.111-3.082 28.168-1.713 54.266-10.968 75.858-28.576 20.49-16.709 33.66-39.279 40.337-65.449 6.368-24.956 6.76-50.358 7.108-75.749C-.288 42.093-.05 21.046 0 0Z"
                style={{
                    fill: "none",
                    stroke: "currentcolor",
                    strokeWidth: 40,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
                transform="translate(491.99 256)"
            />
            <path
                d="M0 0c0-57.99-47.01-105-105-105S-210-57.99-210 0s47.01 105 105 105S0 57.99 0 0Z"
                style={{
                    fill: "none",
                    stroke: "currentcolor",
                    strokeWidth: 40,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
                transform="translate(360 256)"
            />
            <path
                d="M0 0c0-16.568-13.432-30-30-30-16.568 0-30 13.432-30 30 0 16.568 13.432 30 30 30C-13.432 30 0 16.568 0 0"
                style={{
                    fill: "currentcolor",
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "currentcolor",
                }}
                transform="translate(424 391)"
            />
        </g>
    </svg>
)
