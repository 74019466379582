import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { AppContext } from "../../common/appContext.jsx"
import Card from "../../components/card"
import Loading from "../../components/loading/index.jsx"
import LocationAsset from '../../components/locationAsset'
import MusicAsset from "../../components/musicAsset/index.jsx"
import ServiceHourAsset from "../../components/serviceHourAsset/index.jsx"
import { GroupIcon } from "../../images/icons/GroupIcon.jsx"
import { GET_LIST_CLUBS } from "../../queries/content/clubs.js"


export const ClubList = () => {
  const { language } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_LIST_CLUBS(language))

  if (loading) return <Loading />
  if (error) return <div>No restaurants to display.</div>

  const result = data?.allSectionClubs || []

  return (result.map((item) =>
    <Card key={item.id} className="bg-slate-50 px-4 select-none" flat={true}>
      <div>
        <small className="flex">
          <span className="mr-3 flex-auto">
            <GroupIcon /> {item.capacity} <small>PAX</small>
          </span>
          <LocationAsset className="flex-auto h-6" data={item.location} />
        </small>
        <h1 className="text-lg my-2 text-pink-900 font-bold w-full">{item.clubName}</h1>
        <small className="flex my-2">
          <MusicAsset className="flex-auto text-blue-900" data={item.music} />
        </small>
        <ServiceHourAsset data={item.serviceHour} />

        <p className="max-h-36 overflow-y-auto my-3"><span>{item.description}</span></p>
      </div>
    </Card>
  ))
}
export default ClubList
