export const EN_TEXTS = {
  GetInTouch: "Contact Us",
  ContactLegend: "If you have something to tell us or have questions or any special request, you can tell us and we will reach you as soon as possible.",
  ContactPostalAddress: "You can find us on Cancun International Airport.",
  PhoneNumber: 'Phone number',
  Email: 'Email',
  YourName: 'Your name',
  Message: 'Message',
  Submit: 'Submit',
  MenuHome: 'Home',
  MenuTransport: 'Transportation',
  MenuYachts: 'Yachts',
  MenuTours: 'Tours',
  MenuVillas: 'Villas',
  MenuClubs: 'Clubs',
  MenuEvents: 'Events',
  MenuRestaurants: 'Restaurants',
  ContactSentSuccess: 'Thanks! We\'ll get in touch soon',
}
