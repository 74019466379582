import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination } from "swiper";

export const Slider = ({ slides, slidesPerView = 3, images }) => {
  const sliderRef = React.createRef()
  const [slidesToVisualize, setSlidesToVisualize] = useState(slidesPerView)

  useEffect(() => {
    // Display just one item on mobile
    if(sliderRef.current && sliderRef.current.clientWidth < 500) {
      setSlidesToVisualize(1)
    }
  }, [sliderRef])

  return (
    <>
      <Swiper
        ref={sliderRef}
        slidesPerView={slidesToVisualize}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {Array.isArray(images) && images.map((slideImage, k) =>
          <SwiperSlide key={k}><img src={slideImage} alt="" /></SwiperSlide>
        )}

        {Array.isArray(slides) && slides.map((slideItem, i) =>
          <SwiperSlide key={i}>{slideItem}</SwiperSlide>
        )}
      </Swiper>
    </>
  );
}
export default Slider
