import { gql } from "@apollo/client";

export const GET_PAGE_CLUB = (locale) => gql`
query getClubPage {
  pageClub(locale: ${locale}) {
    bannerImage {
      alt
      title
      url
    }
    pageTitle
    description
    itemsPerRow
  }
}
`;
