import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "../../common/appContext.jsx";
import { GET_SERVICE_TRANSFER } from "../../queries/content/serviceTransfer.js";
import Card from "../../components/card/index.jsx";
import Loading from "../../components/loading/index.jsx";

export const TransferList = () => {
  const { language } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_SERVICE_TRANSFER(language))

  if (loading) return <Loading />
  if (error) return <p>Error :(</p>;

  return data.allServiceTransfers.map((item, k) => (
    <Card key={item.id}
      title={item.serviceName}
      image={item?.image?.url}
      text={item.description}
      flat
    />
  ))
}
export default TransferList
