import { useEffect } from "react"

export const PageTitle = ({ title, children }) => {
  useEffect(() => {
    document.title = title || ''
  }, [title])

  return (
    <header className="flex flex-col">
      <h1 className="my-8 text-2xl tracking-wider text-orange-900 font-thin text-center grid">{title}</h1>
      <div className="flex mb-16">
        <p className="md:w-1/2 w-4/5 mx-auto text-justify">
          {children}
        </p>
      </div>
    </header>
  )
}
export default PageTitle
