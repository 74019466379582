import { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./imageGallery.style.css";


export const ImageGallery = ({ images, title }) => {
  const refCarousel = useRef()
  const [carouselWidth, setCarouselWidth] = useState(0)
  const [activeImage, setActiveImage] = useState(0)

  const nextImage = () => {
    let currentIndex = activeImage + 1
    if (currentIndex > (images.length - 1)) {
      currentIndex = 0
    }
    setActiveImage(currentIndex)
  }

  const prevImage = () => {
    let currentIndex = activeImage - 1
    if (currentIndex < 0) {
      currentIndex = images.length - 1
    }
    setActiveImage(currentIndex)
  }

  useEffect(() => {
    if (refCarousel.current) {
      setCarouselWidth(parseInt(refCarousel.current.clientWidth))
    }
  }, [])

  if (!Array.isArray(images)) {
    return null
  }

  if (images.length === 1) {
    const singleImage = images[0]
    return <img src={singleImage.url} className="w-auto rounded-md" alt={singleImage.alt} />
  }

  return (
    <div ref={refCarousel}>
      <section className="flex">
        {images?.map((image, k) => <div key={k}
          className={`image-gallery-image-item ${k === activeImage ? 'active-image' : 'novisible'}`} style={{
            height: `calc(${carouselWidth * .5625}px`,
            width: `${carouselWidth}px`,
            backgroundImage: `url(${image.url})`,
          }}></div>)}
      </section>
      <div className="flex px-4 py-2">
        <span className="flex-auto cursor-pointer" onClick={nextImage}>Previous</span>
        <span className="flex-auto text-right cursor-pointer" onClick={prevImage}>Next</span>
      </div>
    </div>
  )

}
export default ImageGallery
