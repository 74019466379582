import { faFacebook, faInstagram } from '@fortawesome/fontawesome-free-brands';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import React, { useContext } from 'react';
import { AppContext } from '../../common/appContext';
import { Container } from '../../components/containers/index';


const Topbar = () => {

  const RenderPhone = () => contactInfo?.phoneNumber ?
    <>
      <PhoneIcon className="h-6 w-6" aria-hidden="true" />
      <a href={`tel:${contactInfo.phoneNumber}`} className="hidden lg:inline">{contactInfo.phoneNumber}</a>
    </> : null

  const RenderEmail = () => contactInfo?.contactEmail ?
    <>
      <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
      <a href={`mailto:${contactInfo.contactEmail}`} className="hidden lg:inline">{contactInfo.contactEmail}</a>
    </> : null

  const RenderFacebookLink = () => contactInfo.facebookPage ?
    <a href={contactInfo.facebookPage} target="_new">
      <FontAwesomeIcon icon={faFacebook} size="lg" />
    </a> : null

  const RenderInstagramLink = () => contactInfo?.instagramLink ?
    <a href={contactInfo.instagramLink} target="_new">
      <FontAwesomeIcon icon={faInstagram} size="lg" />
    </a> : null

  const { contactInfo } = useContext(AppContext)

  return (<nav className="p-1 mx-auto bg-teal-500">
    <Container className="flex items-center text-white py-2">
      <div className="flex-auto flex text-sm gap-x-3">
        <RenderEmail />
        <span className='w-2' />
        <RenderPhone />
      </div>
      <div className="flex gap-x-2">
        <RenderFacebookLink />
        <RenderInstagramLink />
      </div>
    </Container>
  </nav>)
}

export default Topbar;
