import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { AppContext } from "../../common/appContext.jsx"
import ImageCard from "../../components/imageCard.jsx/index.jsx"
import Loading from "../../components/loading/index.jsx"
import { GET_LIST_RESTAURANTS } from "../../queries/content/restaurants.js"


export const RestaurantList = ({ columns = 4 }) => {
  const { language } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_LIST_RESTAURANTS(language))

  if (loading) return <Loading />
  if (error) return <div>No restaurants to display.</div>

  const result = data?.allSectionRestaurants || []

  return (result.map((item) =>
    <ImageCard key={item.id}
      className="bg-slate-900"
      title={item.shipName}
      gallery={item?.gallery}>
      <div className="px-4 py-3 text-gray-300 w-full">
        <small>{item.location?.locationName}</small>
        <legend className="text-yellow-100 text-2xl">{item.restaurantName}</legend>
        <p className="py-4">{item.description}</p>
      </div>
    </ImageCard>
  ))
}
export default RestaurantList
