import React from "react"


export const ServiceHourAsset = ({ data, className }) => {
  if (!data) { return null }

  const formatHour = (hour) => hour > 12 ? `${hour - 12}pm` : `${hour}am`

  return (<div className={`${className} flex-1`}>
    {data.map(item => <span key={item.id} className="flex">
      {item.day?.weekDay} {formatHour(item.openingTime)} - {formatHour(item.closingHour)}
    </span>
    )}
  </div>)
}
export default ServiceHourAsset
