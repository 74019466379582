import React from 'react';
import { CheckmarkIcon } from '../../images/icons';

const TextView = ({ source }) => {
    const content = source?.value?.document?.children

    const RenderText = ({ type, value }) => {
        switch (type) {
            case 'paragraph': return value.map((v, i) => <p key={i} className="flex-col text-slate-600">{v.value}</p>)

            case 'list':
                let items = value.map(p => p.children[0].children[0].value)
                return <ul className="flex-col ml-4 gap-3 flex mt-3">
                    {items.map((v, i) => <li key={i}>
                        <CheckmarkIcon className="h-8 w-8 text-teal-500" /> <span className="pl-4 text-slate-600">{v}</span>
                    </li>)}
                </ul>
            default: return <div>no sé</div>;
        }
    }

    return (
        <div className="flex gap-4 flex-col">
            {content.map((text, key) => <RenderText key={key} type={text.type} value={text.children} />)}
        </div>
    )
}
export default TextView
