import React from 'react';

export const MailIcon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 305.616 305.616"
        style={{
            enableBackground: "new 0 0 305.616 305.616",
        }}
        xmlSpace="preserve"
        className={`${className || ''} fill-current inline text-base`}
    >
        <path
            d="M24.818 260.333c-24.459 0-.397-24.818-.397-24.818l62.359-68.532c6.065-6.663 17.769-16.78 24.612-11.199 6.842 5.58 15.98 16.888 22.344 23.268l12.896 12.907c6.913 6.119 12.232 0 12.232 0l13.195-12.999c6.418-6.326 15.61-17.362 22.463-22.496 6.853-5.134 18.509 4.629 24.557 11.308l61.994 68.5c24.47 24.062.397 24.062.397 24.062H24.818zm10.585-56.382c-6.065 6.668-14.925 18.264-20.913 24.998-6.761 7.599-14.49 13.369-14.49.06L.294 95.311c.022-9.013-.228-26.2 5.929-23.116 2.29 1.148 5.423 3.535 9.676 7.794l50.464 41.288c6.973 5.706 16.801 12.689 20.016 17.34 2.029 2.932 2.959 6.342.022 9.29l-50.998 56.044zm136.02-47.499c-6.967 5.716-14.539 13.826-18.406 13.837-3.867.005-11.58-7.919-18.58-13.598L24.634 67.621c-7-5.673-19.058-17.1-13.065-20.163 4.329-2.214 11.895-2.176 11.895-2.176h258.029c23.23 0 11.417 11.471 11.417 11.471l-121.487 99.699zm119.687 72.497c-5.999-6.728-14.865-18.33-20.924-24.998l-50.969-56.05c-2.953-2.948-2.029-6.358 0-9.29 3.214-4.65 13.048-11.629 20.027-17.334l37.845-30.959c6.973-5.706 16.143-15.039 22.295-18.123 6.152-3.084 5.885 14.109 5.907 23.116l.326 133.698c.004 13.309-7.736 7.539-14.507-.06z"
        />
    </svg>
)