export const Pages = {
    Home: '/',
    Transportation: '/transportation',
    Yachts: '/yachts',
    Tours: '/tours',
    Villas: '/villas',
    Clubs: '/clubs',
    Events: '/events',
    Restaurants: '/restaurants',
    Contact: '/contact',
    MyConcierge: '/my-concierge',
}

export default Pages
