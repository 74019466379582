import React from 'react';

export const LocationIcon = ({ className }) => (
  <svg
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className || ''} fill-current inline text-base`}
  >
    <path d="M18 1C10.83 1 5 6.83 5 14c0 6.93 11.76 20.11 12.26 20.67.19.21.46.33.74.33s.55-.12.74-.33C19.24 34.11 31 20.93 31 14c0-7.17-5.83-13-13-13zm0 31.48C15.47 29.52 7 19.25 7 14 7 7.93 11.93 3 18 3s11 4.93 11 11c0 5.25-8.47 15.52-11 18.48z" />
    <path d="M18 7c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
  </svg>
)
