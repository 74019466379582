import React from "react"
import { LocationIcon } from "../../images/icons/LocationIcon.jsx"


export const LocationAsset = ({ data, className }) => {
  if (!data) { return null }
  return (<span className={className}>
    <LocationIcon className="h-6 w-6" />
    {data.locationName}
  </span>)
}
export default LocationAsset
