import { gql } from "@apollo/client";

export const GET_LIST_CLUBS = (locale) => gql`
query getClubsContent {
  allSectionClubs(locale: ${locale}) {
    capacity
    clubName
    description
    id
    location {
      locationName
      id
    }
    music {
      id
      musicType
    }
    serviceHour {
      closingHour
      openingTime
      id
      day {
        weekDay
      }
    }
  }
}
`;
