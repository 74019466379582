import React from "react"


export const TextInput = ({ className, id, type = "text", label, placeholder, onChange }) => {

  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      onChange(e.target.value)
    }
  }

  return (
    <div className={className}>
      <label htmlFor={id} className="sr-only">
        {label}
      </label>
      {type === 'textarea' ?
        <textarea
          id={id}
          name={id}
          rows={4}
          className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder || label}
          defaultValue={''}
          onChange={handleChange}
        />
        :
        <input
          type={type}
          name={id}
          id={id}
          autoComplete="auto"
          className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder || label}
          onChange={handleChange}
        />
      }
    </div>
  )
}
