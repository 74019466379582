import React from 'react';


const Title = ({ children, className }) => {
  return (<div className={`flex font-extrabold text-4xl text-teal-600 tracking-wider uppercase ${className||''}`}>
    {children}
  </div>)
}

export default Title;
