import React, { useContext } from "react";
import { AppContext } from "../../common/appContext.jsx";
import Title from "../../components/heading/Title.jsx";
import { FacebookIcon, InstagramIcon, MailIcon, PhoneIcon, WhatsappIcon } from '../../images/icons';
import { ContactForm } from "./ContactForm.jsx";

export const ContactSection = () => {
  const { contactInfo } = useContext(AppContext)

  const RenderContactHeading = ({ contactUsHeading }) => !contactUsHeading ? null : <Title>{contactUsHeading}</Title>

  const RenderContactLegend = ({ contactLegend }) => !contactLegend ? null : (<p className="mt-3 text-lg leading-6 text-gray-500">{contactLegend}</p>)

  const RenderEmail = ({ contactEmail }) => !contactEmail ? null : (
    <div className="mt-3">
      <dt className="sr-only">Email</dt>
      <dd className="flex">
        <MailIcon className="h-7 w-7 text-teal-500" />
        <span className="ml-3"><a href={`mailto:${contactEmail}`}>{contactEmail}</a></span>
      </dd>
    </div>
  )
  const formatPhone = (tel) => `${tel.substring(0, 3)} ${tel.substring(3, 4)} ${tel.substring(4, 7)} ${tel.substring(7, 10)} ${tel.substring(10)}`

  const RenderPhone = ({ phoneNumber }) => !phoneNumber ? null : (
    <div className="mt-6">
      <dt className="sr-only">Phone number</dt>
      <dd className="flex">
        <PhoneIcon className="h-6 w-6 text-teal-500" />
        <span className="ml-3"><a href={`tel:${phoneNumber}`}>{formatPhone(phoneNumber)}</a></span>
      </dd>
    </div>
  )

  const RenderFacebookBigIcon = ({ fbLink }) => !fbLink ? null : (
    <a href={fbLink} target="_new" className="flex-none">
      <FacebookIcon className="h-10 w-10" />
    </a>
  )

  const RenderInstagramBigIcon = ({ igLink }) => !igLink ? null : (
    <a href={igLink} target="_new" className="flex-none">
      <InstagramIcon className="h-10 w-10" />
    </a>
  )

  const RenderWhatsappBigIcon = ({ phone }) => !phone ? null : (
    <a href="https://wa.me/529981669012" target="_new" className="flex-none">
      <WhatsappIcon className="h-10 w-10" />
    </a>
  )

  return (
    <div className="relative bg-white flex-row px-4 lg:px-0">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative mx-auto max-w-5xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 lg:col-span-2 lg:py-24 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <RenderContactHeading contactUsHeading={contactInfo?.contactUsHeading} />
            <RenderContactLegend contactLegend={contactInfo?.contactLegend} />
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Oficina</dt>
                {contactInfo?.findUsOn &&
                  <dd>
                    <p>{contactInfo?.findUsOn}</p>
                  </dd>
                }
              </div>
              <RenderPhone phoneNumber={contactInfo?.phoneNumber} />
              <RenderEmail contactEmail={contactInfo?.contactEmail} />
            </dl>
          </div>
          <div className="mt-4 flex text-teal-500 pt-6 gap-x-4">
            <RenderFacebookBigIcon fbLink={contactInfo?.facebookPage} />
            <RenderInstagramBigIcon igLink={contactInfo?.instagramLink} />
            <RenderWhatsappBigIcon phone={contactInfo?.phoneNumber} />
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )

}
