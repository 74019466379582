import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { AppContext } from "../../common/appContext.jsx"
import ImageCard from "../../components/imageCard.jsx/index.jsx"
import Loading from "../../components/loading/index.jsx"
import { GET_LIST_VILLAS } from "../../queries/content/villas.js"


export const VillasList = () => {
  const { language } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_LIST_VILLAS(language))

  if (loading) return <Loading />
  if (error) return <div>No restaurants to display.</div>

  const result = data?.allSectionVillas || []

  return result.map((item) =>
    <ImageCard key={item.id}
      className="bg-slate-100"
      title={item.shipName}
      gallery={item?.gallery}>
      <div className="px-4 py-3 text-gray-600 w-full">
        <legend className="text-orange-800 text-2xl">{item.accommodationName}</legend>
        <small className="flex flex-wrap">
          <span className="flex-auto  font-bold">{item.location?.locationName}</span>
          <span className="flex-auto  mx-2">Beds: {item.beds}</span>
          <span className="flex-auto  mx-2">Bedrooms: {item.bedrooms}</span>
          <span className="flex-auto  mx-2">Bath: {item.bath}</span>
          <span className="flex-auto  mx-2">Guest: {item.guests}</span>
        </small>
        <p className="py-4 max-h-40 overflow-y-auto">{item.description}</p>
      </div>
    </ImageCard>
  )
}


export default VillasList
