import React, { useContext, useEffect } from 'react'
import VideoBanner from '../../components/video/VideoBanner.jsx'

import { useQuery } from '@apollo/client'
import Loading from '../../components/loading/index.jsx'
import { AppContext } from '../../common/appContext.jsx'
import Title from '../../components/heading/Title'
import imgClubs from '../../images/sections/clubs.jpg'
import imgEvents from '../../images/sections/events.jpg'
import imgRestaurants from '../../images/sections/restaurants.jpg'
import imgTours from '../../images/sections/tours.jpg'
import imgTransport from '../../images/sections/transportation.jpg'
import imgVillas from '../../images/sections/villas.jpg'
import imgYacht from '../../images/sections/yacht.jpg'
import GET_PAGE_HOME from '../../queries/home.js'
import { ContactSection } from '../Contact/ContactSection.jsx'
import Pages from '../index.jsx'
import ServiceCircle from './services/ServiceCircle.jsx'
import TextView from '../../components/textview/TextView.jsx'
import { PhoneIcon } from '../../images/icons/PhoneIcon.jsx'
import Testimonial from '../../components/testimonial/index.jsx'
import Slider from '../../components/slider/index.jsx'

export const HomePage = () => {
  const { language, contactInfo } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_PAGE_HOME(language || 'en'))

  useEffect(() => {
    document.title = 'All Connections Concierge'
  }, [])

  if (loading) return <Loading />
  if (error) return <p>Error loading.</p>;

  const result = data?.home;

  const formatPhone = (tel) => `${tel.substring(0, 3)} ${tel.substring(3, 4)} ${tel.substring(4, 7)} ${tel.substring(7, 10)} ${tel.substring(10)}`

  const RenderPhoneCTA = ({ phone }) => !phone ? null : (
    <div className="flex mt-8 gap-6">
      <div className="flex">
        <PhoneIcon className="h-10 w-10 text-teal-500" />
      </div>
      <div className="flex-col flex text-left">
        <span className="text-slate-500">For information</span>
        <span><a href={`tel:${phone}`} className="text-teal-600 text-2xl">{formatPhone(phone)}</a></span>
      </div>
    </div>
  )

  console.log('data', result);

  return (
    <div className='flex flex-col'>
      <div className='flex-row'>
        <VideoBanner src="assets/video/InShot_20221106_011212381.mp4" />
        <div className="md:absolute bg-teal-600 border-solid pl-4 py-2 text-lg md:text-2xl md:-mt-14 md:bg-opacity-30 w-full text-white font-bold tracking-wider">
          Experience the Mexican Caribbean.
        </div>
      </div>

      <section className='bg-gray-100 py-8 lg:py-16 flex-row px-6 md:px-4 lg:px-0'>
        <div className="flex flex-col gap-6 lg:gap-y-16 max-w-5xl mx-auto py-4 lg:py-12">
          <Title className="flex">Our Services</Title>
          <section className='flex flex-1 gap-2 lg:gap-x-6'>
            <ServiceCircle img={imgClubs} url={Pages.Clubs} imgAlt="Day &amp; Night" />
            <ServiceCircle img={imgEvents} url={Pages.Events} imgAlt="Special events" />
            <ServiceCircle img={imgRestaurants} url={Pages.Restaurants} imgAlt="Restaurants" />
            <ServiceCircle img={imgTours} url={Pages.Tours} imgAlt="Tours" />
            <ServiceCircle img={imgTransport} url={Pages.Transportation} imgAlt="Transportation" />
            <ServiceCircle img={imgVillas} url={Pages.Villas} imgAlt="Villas" />
            <ServiceCircle img={imgYacht} url={Pages.Yachts} imgAlt="Yachts" />
          </section>
        </div>
      </section>

      <section className='flex-row flex-1 py-12 lg:py-24 px-4 lg:px-0'>
        <div className="flex flex-col lg:flex-row max-w-5xl mx-auto lg:gap-x-36">
          <aside className="flex-col flex lg:w-1/2 gap-4">
            <small className='flex-none text-base tracking-wider uppercase text-slate-400'>{result?.topText}</small>
            <Title>
              {result?.aboutTitle}
            </Title>
            <TextView source={result?.aboutDescription} />

            <RenderPhoneCTA phone={contactInfo?.phoneNumber} />
          </aside>
          <aside className='flex-auto lg:w-1/2 w-2/3 mx-auto mt-8 lg:mt-0'>
            <img src={result?.aboutImage?.url} alt="" />
          </aside>
        </div>
      </section>

      {result?.displayPopularDestinations &&
        <section className='bg-gray-100 py-24 flex-row px-4 lg:px-0'>
          <div className="flex max-w-5xl mx-auto flex-col">
            <div className="flex-col flex gap-4 mb-6">
              <small className='flex-none text-base tracking-wider uppercase text-teal-600'>Top destination</small>
              <Title>Popular destinations</Title>
            </div>
            <div className="gap-14 grid grid-cols-3">
            </div>
            <div>
              <Slider images={[
                "assets/destinations/cancun.png",
                "assets/destinations/tulum.png",
                "assets/destinations/riviera-maya.png",
              ]} />
            </div>
          </div>
        </section>
      }

      {result?.displayReviews &&
        <section className='bg-teal-500 py-24 flex-row gap-4 px-4 lg:px-0'>
          <div className="max-w-5xl mx-auto flex flex-col gap-12">
            <div className="flex-auto">
              <aside className="flex-col">
                <small className='flex-none text-sm tracking-wider uppercase text-slate-50'>Testimonials</small>
                <div className="flex font-extrabold text-4xl text-white tracking-wider uppercase">
                  Travelers review
                </div>
              </aside>
            </div>
            <div className="gap-14">
              {Array.isArray(result?.reviews) &&
                <Slider slides={
                  result?.reviews.map((t) => t ? <Testimonial key={t.id} name={t.name} position={t.occupation}>{t.review}</Testimonial> : null)
                } />
              }
            </div>
          </div>
        </section>
      }

      <ContactSection />

    </div>
  )
}

export default HomePage
