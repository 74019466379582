import { gql } from "@apollo/client";

export const GET_LIST_VEHICLES = (locale) => gql`
query getVehicleContent {
  allSectionTransports(locale: ${locale}) {
    vehicleType
    maxPax
    id
    image {
      url
    }
  }
}

`;
