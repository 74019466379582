import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContext } from '../common/appContext.jsx';
import GlobalStrings from '../common/translations/index.jsx';
import PublicLayout from "../layouts/public/PublicLayout.jsx";
import ServiceLayout from '../layouts/service/ServiceLayout.jsx';
import './app.css';
import ClubsPage from './Clubs';
import ContactPage from './Contact/index.jsx';
import EventsPage from "./Events";
import HomePage from "./Home/HomePage.jsx";
import Pages from "./index.jsx";
import MyConcierge from './MyConcierge/index.jsx';
import RestaurantsPage from './Restaurants';
import ToursPage from "./Tours";
import TransportationPage from "./Transportation";
import VillasPage from "./Villas";
import YachtPage from "./Yacht";


function App() {
  const [languageList, setLanguageList] = useState([])
  const [language, setLanguage] = useState('en')
  const [serviceBanner, setServiceBanner] = useState('')
  const [globalStrings, setGlobalStrings] = useState(GlobalStrings[language])
  const [contactInfo, setContactInfo] = useState({})

  useEffect(() => {
    setGlobalStrings(GlobalStrings[language])
  }, [language])

  return (
    <AppContext.Provider value={{
      languageList, setLanguageList,
      language, setLanguage,
      serviceBanner, setServiceBanner,
      globalStrings, setGlobalStrings,
      contactInfo, setContactInfo,
    }}>
      <BrowserRouter>
        <Routes>
          <Route element={<PublicLayout />} exact>
            <Route index element={<HomePage />} path={Pages.Home} />
            <Route element={<ServiceLayout />}>
              <Route exact path={Pages.Transportation} element={<TransportationPage />} />
              <Route exact path={Pages.Yachts} element={<YachtPage />} />
              <Route exact path={Pages.Tours} element={<ToursPage />} />
              <Route exact path={Pages.Villas} element={<VillasPage />} />
              <Route exact path={Pages.Events} element={<EventsPage />} />
              <Route exact path={Pages.Clubs} element={<ClubsPage />} />
              <Route exact path={Pages.Restaurants} element={<RestaurantsPage />} />
            </Route>
            <Route exact path={Pages.MyConcierge} element={<MyConcierge />} />
            <Route exact path={Pages.Contact} element={<ContactPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
