import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../common/appContext.jsx'
import PageTitle from '../../components/heading/PageTitle.jsx'
import Loading from '../../components/loading/index.jsx'
import { GET_PAGE_VILLA } from '../../queries/pages/pageVilla.js'
import VillasList from './villaList.jsx'

export const VillasPage = () => {
    const { setServiceBanner, language } = useContext(AppContext)
    const { loading, error, data } = useQuery(GET_PAGE_VILLA(language))
    const [page, setPage] = useState()

    useEffect(() => {
        setPage(data?.pageVilla)
    }, [data])

    useEffect(() => {
        if (page?.bannerImage) {
            setServiceBanner(page.bannerImage?.url)
        }
    }, [page, setServiceBanner])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return (<>
        <PageTitle title={page?.pageTitle}>{page?.description}</PageTitle>

        <section className={`grid md:grid-cols-${page?.itemsPerRow}  gap-8 select-none`}>
            <VillasList columns={page?.itemsPerRow} />
        </section>
    </>)
}

export default VillasPage
