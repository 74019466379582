import { gql } from "@apollo/client";

export const GET_SERVICE_TRANSFER = (locale) => gql`
query getTransferContent {
  allServiceTransfers(locale: ${locale}) {
    serviceName
    description
    id
    image {
      url
    }
  }
}
`;
