import React from 'react';

function Testimonial({ children, name, position }) {
    return (
        <div className="text-white text-left flex flex-col">
            <article className='bg-white text-slate-500 p-6 text-center flex rounded-md'>
                {children}
            </article>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '1em',
                    height: '0',
                    borderStyle: 'solid',
                    borderColor: '#ffffff transparent transparent transparent',
                    width: '0',
                    borderWidth: '15px 15px 0 15px'
            }}></div>
            <span className="flex-col text-base">{name}</span>
            <span className="flex-col text-sm">{position}</span>
        </div>
    )
}
export default Testimonial
