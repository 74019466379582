import { Link } from "react-router-dom"

export const ServiceCircle = ({ url, img, imgAlt }) => {
  return (
    <Link to={url} className="flex-auto grid grid-cols-1  hover:opacity-80 md:w-8/12 mx-auto">
      <img src={img} alt={imgAlt} className="rounded-full" />
      <span className="md:block text-center mt-2 text-sm hidden text-gray-500">{imgAlt}</span>
    </Link>
  )
}

export default ServiceCircle
