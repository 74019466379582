export const VideoBanner = ({ src }) => {
  return (
    <video className="bg-fixed w-screen" muted autoPlay loop disablePictureInPicture>
      <source
        src={src}
        type="video/mp4" />
    </video>
  )
}

export default VideoBanner
