import { gql } from "@apollo/client";

export const GET_LIST_RESTAURANTS = (locale) => gql`
query getRestaurantContent {
  allSectionRestaurants(locale: ${locale}) {
    restaurantName
    id
    description
    location {
      locationName
      id
    }
    gallery {
      id
      url
      title
      alt
    }
  }
}

`;
