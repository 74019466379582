import { gql } from "@apollo/client";

export const GET_LIST_VILLAS = (locale) => gql`
query getVillaContent {
  allSectionVillas(locale: ${locale}) {
    beds
    bedrooms
    bath
    accommodationName
    description
    gallery {
      title
      url
    }
    guests
    id
    location {
      id
      locationName
    }
  }
}
`;
