import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../common/appContext.jsx'
import PageTitle from '../../components/heading/PageTitle.jsx'
import Loading from '../../components/loading/index.jsx'
import { GET_PAGE_TOUR } from '../../queries/pages/pageTour.js'

export const ToursPage = () => {
    const { setServiceBanner, language } = useContext(AppContext)
    const { loading, error, data } = useQuery(GET_PAGE_TOUR(language))
    const [page, setPage] = useState()

    useEffect(() => {
        setPage(data?.pageTour)
    }, [data])

    useEffect(() => {
        if (page?.bannerImage) {
            setServiceBanner(page.bannerImage?.url)
        }
    }, [page, setServiceBanner])

    if (loading) return <Loading />
    if (error) return <p>Error loading.</p>;

    return <PageTitle title={page?.pageTitle}>{page?.description}</PageTitle>
}

export default ToursPage
