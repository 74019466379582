import { gql } from "@apollo/client";

export const GET_PAGE_YACHT = (locale) => gql`
query getYachtPage {
  pageYacht(locale: ${locale}) {
    bannerImage {
      alt
      title
      url
    }
    pageTitle
    description
    itemsPerRow
  }
}
`;
