import { gql } from "@apollo/client"

export const GET_SITE_INFO = (locale) => gql`
query getSiteInformation {
  _site {
    locales
    globalSeo {
      siteName
    }
  }

  contactinfo: pageContact(locale: ${locale}) {
    phoneNumber
    instagramLink
    findUsOn
    facebookPage
    contactLegend
    contactUsHeading
    contactEmail
  }
}
`
export default GET_SITE_INFO
