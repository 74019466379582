import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { AppContext } from "../../common/appContext.jsx"
import ImageCard from "../../components/imageCard.jsx/index.jsx"
import Loading from "../../components/loading/index.jsx"
import { GET_LIST_YACHTS } from '../../queries/content/yachts.js'

export const YachtList = ({ columns = 4 }) => {
  const { language } = useContext(AppContext)
  const { loading, error, data } = useQuery(GET_LIST_YACHTS(language))

  if (loading) return <Loading />
  if (error) return <div>We don't have yachts here.</div>

  const result = data?.allSectionYachts || []

  return (result.map((item) =>
    <ImageCard key={item.id}
      className="bg-gray-300"
      title={item.shipName}
      gallery={item?.gallery}>
      <div className="px-4 py-3 font-bold text-green-700 w-full">{item.shipName}</div>
    </ImageCard>
  ))
}
export default YachtList
