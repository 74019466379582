import { gql } from "@apollo/client"

export const GET_PAGE_HOME = (locale) => gql`
query getHomePage {
  home(locale: ${locale}) {
    displayReviews
    displayPopularDestinations
    captionForIntroVideo
    topText
    showAboutSectionInHome
    reviews {
      name
      id
      occupation
      review
    }
    aboutTitle
    aboutImage {
      url
      title
      responsiveImage {
        bgColor
      }
    }
    aboutDescription {
      value
    }
    introVideo {
      url
      provider
    }
  }
}
`
export default GET_PAGE_HOME
