import { gql } from "@apollo/client";

export const GET_LIST_YACHTS = (locale) => gql`
query getYachtContent {
  allSectionYachts(locale: en) {
    shipName
    id
    gallery {
      url
    }
  }
}
`;
